@if (opened) {
  <div
    class="fixed inset-0 z-40 h-screen overflow-hidden"
    aria-labelledby="slide-over-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="absolute inset-0 overflow-hidden">
      <!-- Background overlay, show/hide based on slide-over state. -->
      <div
        (click)="backdropClicked()"
        class="cdk-overlay-dark-backdrop absolute inset-0 h-screen backdrop-blur-sm"
        aria-hidden="true"
      ></div>
      <!-- @ngIfOpacity75 -->

      <div class="fixed inset-y-0 right-0 flex max-w-full">
        <!--
          Slide-over panel, show/hide based on slide-over state.
  
          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <div
          class="w-screen"
          @ngIfTransformX
          [ngClass]="opened === 'large' ? 'max-w-3xl' : 'max-w-md'"
        >
          <ng-content></ng-content>
          <!-- <div class="h-full text-white bg-black">asdasdas</div> -->
        </div>
      </div>
    </div>
  </div>
}
