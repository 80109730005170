import {
  animate,
  animateChild,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const ngIfAnimation = trigger('ngIfAnimation', [
  transition(':enter, :leave', [
    query('@*', animateChild(), { optional: true }),
  ]),
]);

export const ngIfFade = trigger('ngIfFade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms ease-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('300ms ease-in', style({ opacity: 0 })),
  ]),
]);

export const ngIfOpacity75 = trigger('ngIfOpacity75', [
  state(
    '*',
    style({
      opacity: 0.75,
    }),
  ),
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  transition('void => *', [style({ opacity: 0 }), animate('300ms linear')]),
  transition('* => void', [style({ opacity: 0.75 }), animate('300ms linear')]),
  // transition(':enter', [
  //   style({ opacity: 0 }),
  //   animate('300ms linear', style({ opacity: 0.75 })),

  // ]),
  // transition(':leave', [
  //   style({ opacity: 0.75 }),
  //   animate('300ms linear', style({ opacity: 0 })),
  // ]),
]);

export const ngIfTransformX = trigger('ngIfTransformX', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('300ms ease-in-out', style({ transform: 'translateX(0px)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }),
    animate('300ms ease-in-out', style({ transform: 'translateX(100%)' })),
  ]),
]);

export const ngIfTransformXInverse = trigger('ngIfTransformXInverse', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('250ms ease-in-out', style({ transform: 'translateX(0px)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }),
    animate('250ms ease-in-out', style({ transform: 'translateX(-100%)' })),
  ]),
]);
