import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PlatformService } from '@memberspot/frontend/shared/util/common';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class BodyService {
  lastScrollPos = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private platformService: PlatformService,
  ) {}

  togglePortalOpenClass(op: 'add' | 'remove') {
    const bodyEl = this.getBody();

    if (op === 'add') {
      bodyEl?.classList.add('portal-open');

      if (this.platformService.isIosTouchDevice && bodyEl) {
        this.lastScrollPos = this.window.scrollY;
        bodyEl.style.top = `-${this.lastScrollPos}px`;
        bodyEl.style.position = 'fixed';
        bodyEl.style.width = '100%';
      }
    } else {
      bodyEl?.classList.remove('portal-open');

      if (this.platformService.isIosTouchDevice && bodyEl) {
        bodyEl.style.removeProperty('position');
        bodyEl.style.removeProperty('top');
        bodyEl.style.removeProperty('width');
        this.window.scrollTo(0, this.lastScrollPos);
      }
    }
  }

  private getBody() {
    return this.document.querySelector('body');
  }
}
