import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
} from '@angular/core';
import {
  ngIfAnimation,
  ngIfOpacity75,
  ngIfTransformX,
} from '@memberspot/frontend/shared/util/animations';

export type PortalOpenState = false | 'large' | 'small' | null;

@Component({
  selector: 'mspot-portal-sidebar',
  templateUrl: './portal-sidebar.component.html',
  styles: [],
  animations: [ngIfOpacity75, ngIfAnimation, ngIfTransformX],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalSidebarComponent {
  @Input() opened: PortalOpenState = false;

  backdropClick = new EventEmitter();

  constructor() {}

  backdropClicked() {
    this.backdropClick.emit();
  }

  @HostListener('document:keydown.esc', ['$event'])
  closePortalViaKeyboard() {
    this.backdropClick.emit();
  }
}
